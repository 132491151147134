<div class="outter-wrapper d-flex flex-column justify-content-center position-relative"
    [style]="!isAboutUs ? { 'padding-top': '0px' } : ''" style="position: relative">
    <div class="d-flex justify-content-center" [class]="{'flex-column': appService.isMobile}">
        <div class="text-wrapper">
            <div class="title">
                {{ "About " + environment.clientName | translate }}
            </div>
            <span *ngIf="!appService.isMobile" class="dash" [style]="!isAboutUs ? { 'top': '65px' } : ''"></span>

            <div *ngIf="isUR">
                <div *ngIf="locale === 'English'" class="content mt-5 realtor-description">
                    <p>
                        {{ environment.clientName }} offers full-service real estate services in buying, selling,
                        investing,
                        leasing
                        and
                        managing residential and commercial real estate in the Triangle area in North Carolina (Cary,
                        Raleigh,
                        Durham, Chapel Hill, Apex, Morrisville, Holly Springs, Fuquay Varina, Garner, Clayton, Wake
                        Forest).
                    </p><br>

                    <p style="padding-bottom: 10px">
                        Today's real estate market in the triangle requires extensive specialization. Our team has over
                        20
                        years
                        of diverse experience in real estate, and our extensive knowledge of the marketplace,
                        understanding
                        of
                        the ever-changing dynamics of the real estate industry, and unique comprehensive marketing
                        strategies,
                        help you make the smartest choice of your real estate need.
                    </p>
                </div>

                <div *ngIf="locale === '简体中文'" class="content mt-5 realtor-description">
                    <p>
                        Universal Realty LLC 提供买卖、投资、租赁等全方位房地产服务
                        和
                        管理北卡罗来纳州三角地区的住宅和商业房地产（卡里，
                        罗利,
                        达勒姆、教堂山、埃佩克斯、莫里斯维尔、霍利斯普林斯、富基瓦里纳、加纳、克莱顿、维克森林）。
                    </p><br>

                    <p style="padding-bottom: 10px">
                        当今三角区的房地产市场需要广泛的专业化。我们的团队有超过20人
                        年
                        拥有丰富的房地产经验，以及我们对市场的广泛了解、理解
                        的
                        房地产行业瞬息万变的动态，独特的综合营销
                        策略，
                        帮助您根据您的房地产需求做出最明智的选择。
                    </p>
                </div>

                <div *ngIf="locale === '繁体中文'" class="content mt-5 realtor-description">
                    <p>
                        Universal Realty LLC 提供買賣、投資、租賃等全方位房地產服務
                        和
                        管理北卡羅來納州三角地區的住宅和商業房地產（卡里，
                        羅利,
                        達勒姆、教堂山、埃佩克斯、莫里斯維爾、霍利斯普林斯、富基瓦里納、加納、克萊頓、維克森林）。
                    </p><br>

                    <p style="padding-bottom: 10px">
                        當今三角區的房地產市場需要廣泛的專業化。我們的團隊有超過20人
                        年
                        擁有豐富的房地產經驗，以及我們對市場的廣泛了解、理解
                        的
                        房地產產業瞬息萬變的動態，獨特的綜合行銷
                        策略，
                        幫助您根據您的房地產需求做出最明智的選擇。
                    </p>
                </div>
            </div>

            <div *ngIf="isProRealty">
                <div class="content mt-5 realtor-description">
                    <p>
                        At {{ environment.clientName }}, we take pride in being the Triangle’s premier real estate team.
                        With over 15 years of experience in Real Estate, we bring world-class service and unmatched
                        expertise to our clients. Your satisfaction is not just our goal—it’s our top priority. Whether
                        you're buying, selling, or investing, we're committed to delivering exceptional results every
                        step of the way.
                    </p><br>
                </div>
            </div>
        </div>

        <div class="image-wrapper pt-5 company-logo">
            <img *ngIf="environment.envType === ENUMS.UNIVERSAL_REALTY_SITE" src="assets/images/logo-ur.png"
                width="400px" height="150px" />
            <img *ngIf="environment.envType === ENUMS.DEMO_SITE" src="assets/images/deb-team-logo.png" width="300px"
                height="200px" />
        </div>
    </div>

    <!-- <span *ngIf="isAboutUs && !appService.isMobile" class="dash" style="top: 600px; width: 80%; left: 10vw"></span> -->

    <div *ngIf="isAboutUs" class="d-flex justify-content-center realtor-section-wrapper">
        <div class="w-25 image-wrapper pt-5 pb-3">
            <img *ngIf="environment.envType === ENUMS.UNIVERSAL_REALTY_SITE" src="assets/images/logo-ur.png"
                width="400px" height="150px" />
            <img *ngIf="environment.envType === ENUMS.DEMO_SITE" src="assets/images/deb-team-logo.png" width="300px"
                height="200px" />
            <img *ngIf="environment.envType === ENUMS.PRO_REALTY_SITE" src="assets/images/Desong-profile-pic.jpeg"
                width="300px" height="300px" />
        </div>
        <div class="w-75">
            <div class="realtor-name mb-4">Desong Yu</div>
            <div class="realtor-description">
                {{ aboutDesong }}
            </div>
        </div>
    </div>

    <div *ngIf="isAboutUs" class="d-flex justify-content-center realtor-section-wrapper alternate-wrapper"
        style="padding-top: 10px">
        <div class="w-25 image-wrapper pt-5 pb-3">
            <img *ngIf="environment.envType === ENUMS.UNIVERSAL_REALTY_SITE" src="assets/images/logo-ur.png"
                width="400px" height="150px" />
            <img *ngIf="environment.envType === ENUMS.DEMO_SITE" src="assets/images/deb-team-logo.png" width="300px"
                height="200px" />
            <img *ngIf="environment.envType === ENUMS.PRO_REALTY_SITE" src="assets/images/Bin-profile-pic.jpeg"
                width="300px" height="300px" />
        </div>

        <div class="w-75">
            <div class="realtor-name mb-4">Bin Liu</div>
            <div class="realtor-description">
                {{ aboutBin }}
            </div>
        </div>
    </div>

    <div *ngIf="isAboutUs" class="d-flex justify-content-center realtor-section-wrapper" style="padding-top: 10px">
        <div class="w-25 image-wrapper pt-5 pb-3">
            <img *ngIf="environment.envType === ENUMS.UNIVERSAL_REALTY_SITE" src="assets/images/logo-ur.png"
                width="400px" height="150px" />
            <img *ngIf="environment.envType === ENUMS.DEMO_SITE" src="assets/images/deb-team-logo.png" width="300px"
                height="200px" />
        </div>

        <!-- <div class="w-75">
            <div class="realtor-name mb-4">Realtor Name</div>
            <div class="realtor-description">Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the
                industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
                scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap
                into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the
                release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing
                software like Aldus PageMaker including versions of Lorem Ipsum.</div>
        </div> -->
    </div>

    <app-contact-us *ngIf="isAboutUs" class="contact-us-wrapper"
        [showMap]="environment.enableGoogleMaps"></app-contact-us>

    <app-footer *ngIf="isAboutUs" class="footer"></app-footer>


    <!-- <div *ngIf="isAboutUs" class="filler"></div> -->

</div>