import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactUsComponent } from '../contact-us/contact-us.component';
import { environment } from '../../env/environment';
import { ENUMS } from '../../models/constants';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { AppServiceService } from '../services/app-service.service';
import { FooterComponent } from '../footer/footer.component';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-about-us',
  standalone: true,
  imports: [
    CommonModule,
    ContactUsComponent,
    FooterComponent,
    TranslateModule,
  ],
  templateUrl: './about-us.component.html',
  styleUrl: './about-us.component.scss',
})
export class AboutUsComponent implements OnInit {

  environment = environment;
  isAboutUs = true;
  locale = 'English';
  ENUMS = ENUMS;

  isUR = false;
  isProRealty = false;

  aboutDesong = '';
  aboutBin = '';

  constructor(
    private router: Router,
    public appService: AppServiceService,
    public authService: AuthService,
  ) {

    this.authService.checkRoute();

    this.isAboutUs = this.router.url.includes('about-us');

    this.isUR = environment.envType === ENUMS.UNIVERSAL_REALTY_SITE;
    this.isProRealty = environment.envType === ENUMS.PRO_REALTY_SITE;
  }

  ngOnInit() {
    // this.appService.scrollToTop();

    console.log(this.appService.isMobile)

    this.appService.getData().subscribe((data: any) => {
      if (data && data.type === 'translate') {
        this.locale = data.value;
      }
    })

    this.aboutDesong = 'After starting my real estate journey 15 years ago, I’ve had the pleasure of working with many incredible clients. Along the way, I’ve learned that the key to achieving win-win results lies in combining passion, market expertise, kindness, honesty, and a genuine commitment to delivering exceptional service. My goal is to provide personalized guidance that helps my clients navigate the ever-changing real estate market with confidence and ease. From the moment you come on board to the day you receive the keys to your dream home, I’ll be here to listen, support, and serve every step of the way. Let’s begin your journey together!'
    this.aboutBin = 'Over 15 years in the real estate industry, I have had the privilege of assisting many clients in finding their dream homes and investment properties. My journey has taught me that the key to success lies in a genuine passion for helping others, coupled with a deep understanding of the market and a commitment to integrity and transparency. Whether you’re a first-time homebuyer, looking to sell, or seeking an investment opportunity, my goal is to make the process as seamless and enjoyable as possible. Let\'s work together to turn your real estate dream into reality!';
  }

}
